import React from 'react'
import Container from '../../Components/Container'
import Text from '../../Components/Text'
import Icon from '../../Components/Icon'
import Link from '../../Components/Link'

const Sidebar = () => (
    <Container
        style={{
            width: '11em',
            padding: '4em 0 0 1em',
            heigght: 'calc(100% - 9em)',
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
    >
        <Link to="/">
            <Icon style={{ marginRight: '.5em' }} color="#B63939">
                dashboard
            </Icon>
            <Text>Dashboard</Text>
        </Link>
        <Link to="/create">
            <Icon style={{ marginRight: '.5em' }} color="#B63939">
                add_box
            </Icon>
            <Text>Create season</Text>
        </Link>
    </Container>
)

export default Sidebar
