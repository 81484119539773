import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Container, { ContainerAsForm } from '../../Components/Container'
import { Input } from '../../Components/Input'
import Button from '../../Components/Button'
import { errorAction } from '../../Actions/utilsActions'
import Text from '../../Components/Text'
import DateTimePicker from '../../Components/DateTimePicker'
import { addSeason, scheduleStart } from '../../Actions/seasonActions'
import Checkbox from '../../Components/Checkbox'
import Flex from '../../Components/Container/Flex'
import Loading from '../../Components/Loading'

class CreateSeason extends Component {
    constructor(props) {
        super(props)

        this.state = {
            amount: 0,
            paywall: false,
            isPrivate: false,
            time: new Date(),
            description: '',
            name: '',
            photo: '',
            loading: false,
        }
    }

    onChange = name => e => this.setState({ [name]: e.target.value })

    createSeason = e => {
        e.preventDefault()
        const { dispatch, history } = this.props

        const { name, description, time, amount, paywall, isPrivate, photo } = this.state
        const data = paywall
            ? { name, description, amount: amount * 100, isPrivate, photo }
            : { name, description, isPrivate, photo }
        if ((name, description)) {
            this.setState({ loading: true })
            dispatch(addSeason(data)).then(action =>
                dispatch(scheduleStart(action.payload.data.uid, time.getTime())).then(() => {
                    this.setState({ loading: false })
                    history.push(`/${action.payload.data.uid}`)
                })
            )
        } else {
            this.setState({ loading: false })
            dispatch(errorAction('One or more empty fields'))
        }
    }

    render = () => {
        const { description, name, time, paywall, amount, isPrivate, loading, photo } = this.state

        return (
            <Container vertical={false} alignItems="stretch">
                <ContainerAsForm
                    alignItems="stretch"
                    style={{ maxWidth: '20em', flex: 1 }}
                    onSubmit={this.createSeason}
                >
                    <Input value={name} onChange={this.onChange('name')} placeholder="name" />
                    <Input value={photo} onChange={this.onChange('photo')} placeholder="photo" />
                    <Input
                        multiline
                        value={description}
                        onChange={this.onChange('description')}
                        placeholder="description"
                    />
                    <Checkbox checked={isPrivate} onChange={e => this.setState({ isPrivate: e })}>
                        Private
                    </Checkbox>
                    <Checkbox checked={paywall} onChange={e => this.setState({ paywall: e })}>
                        Payment
                    </Checkbox>
                    {paywall && (
                        <Container vertical={false} alignItems="flex-end">
                            <Flex>
                                <Input
                                    value={amount}
                                    onChange={this.onChange('amount')}
                                    type="number"
                                    placeholder="amount"
                                />
                            </Flex>
                            <Text style={{ marginLeft: '1em' }}>€</Text>
                        </Container>
                    )}
                    <Text style={{ marginTop: '1em' }}>Starts at:</Text>
                    <DateTimePicker onChange={date => this.setState({ time: date })} value={time} />
                    {loading ? <Loading /> : <Button type="submit">create</Button>}
                </ContainerAsForm>
            </Container>
        )
    }
}

CreateSeason.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
}

const mapStateToProps = () => ({})

export default withRouter(connect(mapStateToProps)(CreateSeason))
