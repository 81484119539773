import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Route, Link } from 'react-router-dom'
import dayjs from 'dayjs'

import Container from '../../Components/Container'
import Box from './Box'
import Item from './Item'
import Flex from '../../Components/Container/Flex'
import Icon from '../../Components/Icon'
import Text from '../../Components/Text'
import SeasonEditor from '../../Components/SeasonEditor'
// import Text from '../../Components/Text'
// import Button from '../../Components/Button'

const Label = styled.h1`
    text-align: center;
    font-size: 2.6em;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
`

class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    filterOldSeasons = () => {
        const now = dayjs().valueOf()

        return season => {
            try {
                if (season.start && season.start > now) {
                    return true
                }

                if (season.events && season.events.length > 0) {
                    const { events } = season

                    events.sort((a, b) => a.end - b.end)
                    if (events[events.length - 1].end > now) {
                        return true
                    }
                }
            } catch (e) {
                return false
            }
        }
    }

    render = () => {
        const { seasons } = this.props
        // const now = new Date().getTime()
        return (
            <Container alignItems="stretch">
                <Label>Dashboard</Label>
                <Container
                    vertical={false}
                    alignItems="flex-start"
                    style={{ margin: '1em 0 3em 0' }}
                >
                    <Box label="Past Seasons" color="#C13b3b">
                        {seasons &&
                            seasons.map(season => (
                                <Item key={season.uid}>
                                    <Flex>
                                        <Link to={`/${season.uid}`}>
                                            <Text>{season.name}</Text>
                                        </Link>
                                    </Flex>
                                    <Flex>
                                        <Container vertical={false} justifyContent="flex-end">
                                            <Icon style={{ marginRight: '.5em', color: '#b63939' }}>
                                                visibility
                                            </Icon>
                                            <Text>View data</Text>
                                        </Container>
                                    </Flex>
                                </Item>
                            ))}
                    </Box>
                    <Box label="Current Seasons" color="#3B82C1">
                        {seasons.filter(this.filterOldSeasons()).map(season => (
                            <Item key={season.uid}>
                                <Flex>
                                    <Link to={`/${season.uid}`}>
                                        <Text>{season.name}</Text>
                                    </Link>
                                </Flex>
                                <Flex>
                                    <Container vertical={false} justifyContent="flex-end">
                                        <Icon style={{ marginRight: '.5em', color: '#b63939' }}>
                                            visibility
                                        </Icon>
                                        <Text>View data</Text>
                                    </Container>
                                </Flex>
                            </Item>
                        ))}
                    </Box>
                </Container>
                <Route path="/:uid" exact>
                    <Container vertical={false} alignItems="stretch">
                        <SeasonEditor />
                    </Container>
                </Route>
            </Container>
        )
    }
}

Home.propTypes = {
    seasons: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = state => ({
    seasons: state.seasons.seasons,
})

export default connect(mapStateToProps)(Home)
