import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Container, { ContainerAsForm } from '../../Components/Container'
import { auth } from '../../Config/auth'
import Button from '../../Components/Button'
import { Input } from '../../Components/Input'
import Text from '../../Components/Text'
import { updateUser } from '../../Actions/userActions'

class Profile extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            displayName: '',
            telegramUsername: '',
        }
    }

    componentDidMount = () => this.setPropsToState()

    componentDidUpdate = prevProps => this.handlePropsUpdate(prevProps)

    handlePropsUpdate = prevProps => {
        const {
            user: { email, displayName, telegramUsername },
        } = this.props
        const {
            user: {
                email: prevEmail,
                displayName: prevDisplayName,
                telegramUsername: prevTelegramUsername,
            },
        } = prevProps

        if (
            email !== prevEmail ||
            displayName !== prevDisplayName ||
            telegramUsername !== prevTelegramUsername
        ) {
            this.setPropsToState()
        }
    }

    setPropsToState = () => {
        const {
            user: { email, displayName, telegramUsername },
        } = this.props

        const {
            email: stateEmail,
            displayName: stateDisplayName,
            telegramUsername: stateTelegramUsername,
        } = this.state

        if (email !== stateEmail) {
            this.setState({ email })
        }
        if (displayName !== stateDisplayName) {
            this.setState({ displayName })
        }
        if (telegramUsername !== stateTelegramUsername) {
            this.setState({ telegramUsername })
        }
    }

    updateUser = e => {
        if (e) e.preventDefault()
        const { dispatch } = this.props
        const { email, displayName, telegramUsername } = this.state
        dispatch(updateUser(email, displayName, telegramUsername))
    }

    handleChange = name => e => {
        this.setState({
            [name]: e.target.value,
        })
    }

    render = () => {
        const { email, displayName, telegramUsername } = this.state
        console.log(email, displayName, telegramUsername)
        return (
            <Container>
                <Button onClick={() => auth.signOut()} style={{ margin: '2em' }}>
                    LOGOUT
                </Button>
                <hr style={{ width: 'calc(100% - 2em)' }} />
                <Text style={{ fontSize: '1.7m', margin: '.5em 0' }}>Update your profile:</Text>
                <ContainerAsForm
                    onSubmit={this.updateUser}
                    vertical
                    alignItems="stretch"
                    style={{ width: '15em' }}
                >
                    <Input
                        placeholder="EMAIL"
                        value={email}
                        onChange={this.handleChange('email')}
                    />
                    <Input
                        placeholder="NAME"
                        value={displayName}
                        onChange={this.handleChange('displayName')}
                    />
                    <Input
                        placeholder="TELEGRAM USERNAME"
                        value={telegramUsername}
                        onChange={this.handleChange('telegramUsername')}
                    />
                    <Button type="submit">save</Button>
                </ContainerAsForm>
            </Container>
        )
    }
}

Profile.propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.shape({
        email: PropTypes.string,
        displayName: PropTypes.string,
        telegramUsername: PropTypes.string,
    }),
}

Profile.defaultProps = {
    user: {
        email: '',
        displayName: '',
        telegramUsername: '',
    },
}

const mapStateToProps = state => ({
    user: state.user.user,
})

export default connect(mapStateToProps)(Profile)
