import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import { store } from './index'
import { loginCallback, logout } from '../Actions/authActions'
import { getUser } from '../Actions/userActions'

export default firebase.initializeApp({
    apiKey: 'AIzaSyDJ7KyF19Sjd5dWs7kCa1rt8f2ER-4kXAY',
    authDomain: 'mobilereality-bot.firebaseapp.com',
    databaseURL: 'https://mobilereality-bot.firebaseio.com',
    projectId: 'mobilereality-bot',
    storageBucket: 'mobilereality-bot.appspot.com',
    messagingSenderId: '871066073229',
    appId: '1:871066073229:web:45341ed5cdbb8e32370ac4',
    measurementId: 'G-10499E6MD6',
})

firebase.analytics()

firebase.auth().useDeviceLanguage()

firebase.auth().onAuthStateChanged(async user => {
    if (user) {
        try {
            const token = await user.getIdToken(true)
            store.dispatch(
                loginCallback(token, {
                    name: user.displayName,
                    email: user.email,
                    emailVerified: user.emailVerified,
                    uid: user.uid,
                })
            )
            store.dispatch(getUser())
        } catch (e) {
            console.error(e)
        }
    } else {
        store.dispatch(logout())
    }
})

export const auth = firebase.auth()
