import { event } from '../Actions/Types'

const initialState = {
    types: [],
}

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case event.EVENT_TYPES_SUCCESS:
            return {
                ...state,
                types: action.payload.data,
            }

        default:
            return state
    }
}

export default eventReducer
