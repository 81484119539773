export const EVENT_TYPES = 'EVENT_TYPES'
export const EVENT_TYPES_SUCCESS = 'EVENT_TYPES_SUCCESS'
export const EVENT_TYPES_FAILURE = 'EVENT_TYPES_FAILURE'

export const ADD_EVENT = 'ADD_EVENT'
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS'
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE'

export const EDIT_EVENT = 'EDIT_EVENT'
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS'
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE'

export const REMOVE_EVENT = 'REMOVE_EVENT'
export const REMOVE_EVENT_SUCCESS = 'REMOVE_EVENT_SUCCESS'
export const REMOVE_EVENT_FAILURE = 'REMOVE_EVENT_FAILURE'
