import styled from 'styled-components'

const Item = styled.div`
    height: 2.5em;
    display: flex;
    flex-direction: row;
    align-items: center;

    border-bottom: ${({ theme }) => theme.border.default};
    border-color: rgba(255, 255, 255, 0.1);
`

export default Item
