import { season } from './Types'

export const getUserSeasons = () => (dispatch, getState) => {
    const {
        user: { uid },
    } = getState().user

    dispatch({
        type: season.GET_SEASONS,
        payload: {
            request: {
                method: 'GET',
                url: `/user/${uid}/seasons`,
            },
        },
    })
}

export const joinSeason = (uid, user) => ({
    type: season.JOIN_SEASON,
    payload: {
        request: {
            method: 'POST',
            url: `/seasons/${uid}/join`,
            data: {
                ...user,
            },
        },
    },
})

export const getSeason = uid => ({
    type: season.GET_SEASON,
    payload: {
        request: {
            method: 'GET',
            url: `/seasons/${uid}`,
        },
    },
})

export const addSeason = data => ({
    type: season.ADD_SEASON,
    payload: {
        request: {
            method: 'POST',
            url: '/seasons',
            data,
        },
    },
})

export const scheduleStart = (uid, time) => ({
    type: season.SCHEDULE_START,
    payload: {
        request: {
            method: 'POST',
            url: `/seasons/${uid}/scheduleStart`,
            data: {
                time,
            },
        },
    },
})
