import { event } from './Types'

export const getEventTypes = () => ({
    type: event.EVENT_TYPES,
    payload: {
        request: {
            method: 'GET',
            url: `/event_types`,
        },
    },
})

export const addEvent = (item, season) => ({
    type: event.ADD_EVENT,
    payload: {
        request: {
            method: 'POST',
            url: `seasons/${season}/events/`,
            data: {
                type: item.type,
                options: item,
                vote: item.vote,
                start: item.start.valueOf(),
            },
        },
    },
})

export const editEvent = (item, season) => ({
    type: event.EDIT_EVENT,
    payload: {
        request: {
            method: 'PATCH',
            url: `seasons/${season}/events/${item.uid}`,
            data: {
                options: item,
                vote: item.vote,
                start: item.start.valueOf(),
            },
        },
    },
})

export const removeEvent = (item, season) => ({
    type: event.REMOVE_EVENT,
    payload: {
        request: {
            method: 'DELETE',
            url: `seasons/${season}/events/${item}`,
        },
    },
})
