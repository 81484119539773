import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import { auth } from '../../Config/auth'

import Container, { ContainerAsForm } from '../../Components/Container'
import Button from '../../Components/Button'
import Logo from '../../Components/Logo'
import { ErrorInput } from '../../Components/Input'
import { errorAction, messageAction } from '../../Actions/utilsActions'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            error: false,
        }
    }

    onChange = name => e => this.setState({ [name]: e.target.value })

    revive = e => {
        e.preventDefault()
        const { email } = this.state
        const { dispatch } = this.props

        if (email) {
            auth.sendPasswordResetEmail(email)
                .then(() => {
                    dispatch(messageAction('We sent you email with instructions!'))
                })
                .catch(error => {
                    dispatch(errorAction(error.message))
                })
        } else {
            dispatch(errorAction('Email cannot be empty'))
        }
    }

    render = () => {
        const {
            token,
            location: { state },
        } = this.props

        const { email, error } = this.state

        if (token) {
            return <Redirect to={(state && state.from) || '/'} />
        }

        return (
            <Container style={{ flex: 1 }}>
                <Logo />
                <ContainerAsForm
                    onSubmit={this.revive}
                    vertical
                    alignItems="stretch"
                    style={{ width: '15em' }}
                >
                    <ErrorInput
                        value={email}
                        onChange={this.onChange('email')}
                        error={error}
                        placeholder="email"
                    />
                </ContainerAsForm>
                <Container
                    vertical={false}
                    justifyContent="space-between"
                    style={{ fontSize: '.7em', width: '21.4285714em', marginTop: '4em' }}
                >
                    <Link to="/login">
                        <Button style={{ color: 'rgba(147, 56, 59, 0.9' }}>back</Button>
                    </Link>
                    <Button onClick={this.revive}>Revive</Button>
                </Container>
            </Container>
        )
    }
}

Login.propTypes = {
    token: PropTypes.string.isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            from: PropTypes.string,
        }),
    }),
    dispatch: PropTypes.func.isRequired,
}

Login.defaultProps = {
    location: {
        state: {
            from: '/',
        },
    },
}

const mapStateToProps = state => ({
    token: state.user.token,
})

export default connect(mapStateToProps)(Login)
