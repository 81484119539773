/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Select from 'react-select'
import styled from 'styled-components'

import { HightlightButton } from '../Button'
import { ContainerAsForm } from '../Container'
import Text from '../Text'
import { Input } from '../Input'
import Checkbox from '../Checkbox'
import StyledDateTimePicker from '../DateTimePicker'
import Loading from '../Loading'

export const voteOptions = [
    { label: 'No vote', value: 'NULL' },
    { label: 'Normal vote', value: 'NORMAL' },
    { label: 'Inversed vote', value: 'INVERSE' },
    { label: 'Instant elimination', value: 'INSTANT' },
]

const StyledSelect = styled(Select)`
    margin-top: 1em;
`

class EventEditor extends Component {
    constructor(props) {
        super(props)

        let start
        let end

        if (props.event.start && props.event.end) {
            start = dayjs(props.event.start).toDate()
            end = dayjs(props.event.end).toDate()
        } else {
            start = dayjs().toDate()
            end = dayjs()
                .add(1, 'hour')
                .toDate()
        }

        this.state = {
            voteValue: voteOptions[0],
            number_to_vote_from: 5,
            number_to_eliminate: 5,
            vote_duration: 30,
            number_of_tribes: 0,
            vote_text: '',
            ...props.event.vote,
            ...props.event.options,
            start,
            end,
        }
    }

    componentDidUpdate = prevProps => {
        if (
            prevProps.event.uid !== this.props.event.uid ||
            prevProps.event.id !== this.props.event.id
        ) {
            let start
            let end

            if (this.props.event.start && this.props.event.end) {
                start = dayjs(this.props.event.start).toDate()
                end = dayjs(this.props.event.end).toDate()
            } else {
                start = dayjs().toDate()
                end = dayjs()
                    .add(1, 'hour')
                    .toDate()
            }

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                voteValue: voteOptions[0],
                number_to_vote_from: 5,
                number_to_eliminate: 5,
                vote_duration: 30,
                number_of_tribes: 0,
                vote_text: '',
                ...this.props.event.vote,
                ...this.props.event.options,
                start,
                end,
            })
        }
    }

    handleChange = name => e => {
        if (e.hasOwnProperty('target')) {
            console.log(e.target.value)
            this.setState({
                ...this.state,
                [name]: e.target.value,
            })
        } else {
            this.setState({
                ...this.state,
                [name]: e,
            })
        }
    }

    handleSubmit = e => {
        e.preventDefault()
        const { handleSubmit } = this.props
        handleSubmit(this.state)
    }

    renderField = field => {
        console.log(this.state[field.value], field.value, field.options, this.state[field.option])

        switch (field.type) {
            case 'TEXT_ARRAY_INPUT': {
                return this.renderSelect(field)
            }
            case 'BOOLEAN_INPUT': {
                return this.renderCheckBox(field)
            }
            case 'NUMBER_INPUT': {
                return (
                    <Input
                        key={field.title}
                        value={this.state[field.value]}
                        placeholder={field.title}
                        onChange={this.handleChange(field.value)}
                        type="number"
                    />
                )
            }
            case 'FLOAT_INPUT': {
                return (
                    <Input
                        key={field.title}
                        value={this.state[field.value]}
                        placeholder={field.title}
                        onChange={this.handleChange(field.value)}
                        type="number"
                    />
                )
            }
            case 'LONG_TEXT_INPUT': {
                return (
                    <Input
                        key={field.title}
                        value={this.state[field.value]}
                        multiline={true}
                        placeholder={field.title}
                        onChange={this.handleChange(field.value)}
                        type="text"
                    />
                )
            }
            default: {
                return (
                    <Input
                        key={field.title}
                        value={this.state[field.value]}
                        multiline={false}
                        placeholder={field.title}
                        onChange={this.handleChange(field.value)}
                        type="text"
                    />
                )
            }
        }
    }

    renderSelect = field => (
        <StyledSelect
            key={field.title}
            value={
                this.state[field.value]
                    ? this.state[field.value].map(value => ({ label: value, value }))
                    : []
            }
            styles={{ menu: provided => ({ ...provided, zIndex: 999 }) }}
            isMulti
            options={
                this.state[field.options]
                    ? this.state[field.options].map(value => ({ label: value, value }))
                    : []
            }
            onChange={selected =>
                selected && selected.length
                    ? this.setState({
                          [field.value]: selected.map(item => item.value),
                      })
                    : this.setState({
                          [field.value]: [],
                      })
            }
        />
    )

    renderCheckBox = field => (
        <Checkbox
            key={field.title}
            checked={this.state[field.value]}
            onChange={this.handleChange(field.value)}
            children={field.title}
        />
    )

    renderVote = () => {
        switch (this.state.voteValue.value) {
            case 'INSTANT':
                return (
                    <>
                        <Input
                            value={this.state.vote_duration.toString()}
                            onChange={this.handleChange('vote_duration')}
                            placeholder="Vote duration"
                            type="number"
                        />
                        <Input
                            value={this.state.vote_text}
                            multiline
                            onChange={this.handleChange('vote_text')}
                            placeholder="Vote message"
                        />
                        <Input
                            value={this.state.number_to_eliminate}
                            type="number"
                            onChange={this.handleChange('number_to_eliminate')}
                            placeholder="Number of players to eliminate"
                        />
                    </>
                )

            default:
                return (
                    <>
                        <Input
                            value={this.state.vote_duration}
                            onChange={this.handleChange('vote_duration')}
                            placeholder="Vote duration"
                            type="number"
                        />
                        <Input
                            value={this.state.vote_text}
                            multiline
                            onChange={this.handleChange('vote_text')}
                            placeholder="Vote message"
                        />
                        <Input
                            value={this.state.number_to_eliminate}
                            type="number"
                            onChange={this.handleChange('number_to_eliminate')}
                            placeholder="Number of players to eliminate"
                        />
                        <Input
                            value={this.state.number_to_vote_from}
                            type="number"
                            onChange={this.handleChange('number_to_vote_from')}
                            placeholder="Number of players to vote from"
                        />

                        <Input
                            value={this.state.number_of_tribes}
                            type="number"
                            onChange={this.handleChange('number_of_tribes')}
                            placeholder="Number of worst tribes to vote in"
                        />
                    </>
                )
        }
    }

    render = () => {
        const { event } = this.props
        console.log(this.state)
        return (
            <ContainerAsForm
                onSubmit={this.handleSubmit}
                alignItems="stretch"
                style={{ padding: '1em' }}
            >
                <Text bold>{event.options.name || event.options.title}</Text>
                <Text style={{ marginTop: '1em' }}>Start:</Text>
                <StyledDateTimePicker
                    value={this.state.start}
                    onChange={start => this.setState({ start })}
                />
                {/* <Text style={{ marginTop: '1em' }}>End:</Text>
                <StyledDateTimePicker
                    value={this.state.end}
                    onChange={end => this.setState({ end })}
                /> */}
                {event.options.fields && event.options.fields.map(field => this.renderField(field))}
                <div style={{ height: '1em' }} />
                <StyledSelect
                    options={voteOptions}
                    value={this.state.voteValue}
                    onChange={voteValue => this.setState({ voteValue })}
                />
                {this.state.voteValue.value !== 'NULL' && this.renderVote()}
                {this.props.loading ? (
                    <Loading />
                ) : (
                    <HightlightButton style={{ margin: '1em 2em 0 2em' }} type="submit">
                        Save
                    </HightlightButton>
                )}
            </ContainerAsForm>
        )
    }
}

EventEditor.propTypes = {
    event: PropTypes.shape({
        options: PropTypes.object,
        vote: PropTypes.object,
        start: PropTypes.number,
        end: PropTypes.number,
        uid: PropTypes.string,
        id: PropTypes.number,
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    handleSubmit: PropTypes.func.isRequired,
}

export default EventEditor
