import { season } from '../Actions/Types'

const initialState = {
    loading: false,
    seasons: [],
    selected: null,
}

const seasonsReducer = (state = initialState, action) => {
    switch (action.type) {
        case season.GET_SEASONS_SUCCESS:
            return {
                ...state,
                loading: false,
                seasons: action.payload.data,
            }

        case season.GET_SEASON_SUCCESS:
        case season.SCHEDULE_START_SUCCESS:
            if (state.seasons.find(seasonItem => seasonItem.uid === action.payload.data.uid)) {
                return {
                    loading: false,
                    seasons: state.seasons.map(seasonItem =>
                        seasonItem.uid === action.payload.data.uid
                            ? action.payload.data
                            : seasonItem
                    ),
                }
            }
            return {
                loading: false,
                seasons: [...state.seasons, action.payload.data],
            }

        case season.ADD_SEASON_SUCCESS:
            return {
                ...state,
                seasons: [...state.seasons, action.payload.data],
            }

        default:
            return state
    }
}

export default seasonsReducer
