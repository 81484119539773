/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable indent */
/**
 *  Name
 * Author: Niilo Jaakkola <niilo.jaakkola@ domain.fi>
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router-dom'
import dayjs from 'dayjs'
import Select from 'react-select'

import Text from '../Text'
import Loading from '../Loading'
import { getSeason, scheduleStart } from '../../Actions/seasonActions'
import Flex from '../Container/Flex'
import Container, { ContainerAsForm } from '../Container'
import Button, { HightlightButton } from '../Button'
import Dialog from '../Dialog'
import { getEventTypes, addEvent, editEvent, removeEvent } from '../../Actions/eventActions'
import EventEditor, { voteOptions } from './editEvent'
import StyledDateTimePicker from '../DateTimePicker'
import { id } from '../../Actions/utilsActions'

const STATUSES = {
    INITIALIZING: 'Season needs initializing',
    ADDING_PLAYERS: 'Players can join',
    IDLE: 'Started',
    EVENT_ACTIVE: 'Event ongoing',
}

const MARGIN_LEFT = '10.225em'
const MARGIN_LEFT_N = 10.225

const BoxContainer = styled.div`
    flex: 1;
    max-width: 50em;
    margin: 0 1em 3em 1em;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`

const InnerContainer = styled.div`
    border-radius: 0 0 4px 4px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3em 1em;
    flex: 1;
`

const Header = styled.div`
    font-size: 1.5em;
    font-weight: bold !important;
    background-color: #a63a3a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7em;
    border-radius: 4px 4px 0 0;
`

const SecondaryHeader = styled.div`
    background-color: rgba(166, 58, 58, 0.8);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7em 1em;
`

const GreyText = styled(Text)`
    font-size: 1.5em;
    color: rgba(255, 255, 255, 0.5);
    margin-right: 1em;
`

const Dot = styled.div`
    position: relative;
    z-index: 1;
    margin-right: 1em;
    background-color: #a63a3a;
    margin-top: -0.7em;
    height: 1.4em;
    width: 1.4em;
    border-radius: 4em;
    box-shadow: rgba(0, 0, 0, 0.1) 0 3px 6px 0;
`

const ContentRight = styled.div`
    border-left: 2px solid #a63a3a;
    padding: 0 0 2.5em 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`

const ContentLeft = styled.div`
    padding: 0 0 2.5em 0;
    width: ${MARGIN_LEFT_N + 0.65}em;
    display: flex;
`

const ContentItem = styled.div`
    height: 2em;
    margin-top: ${({ marginTop = 1 }) => marginTop * 4}em;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ${({ gray }) =>
        gray &&
        css`
            span {
                color: gray;
                font-size: 0.9em;
            }
        `}
`

const Cube = styled.div`
    background-color: #a63a3a;
    width: 0.75em;
    height: 0.75em;
    transform: rotateZ(45deg);
    margin-left: calc(-0.375em - 1px);
    margin-right: 2.5em;
`

const Dash = styled.div`
    background-color: grey;
    width: 1.05em;
    height: 0.3em;
    margin-left: calc(-0.525em - 1px);
    margin-right: 2.4em;
`

const DateItem = styled.div`
    background-color: ${({ today }) => (today ? '#d9bb29' : '#3b82c1')};
    padding: 0.1em 0.6em;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledSelect = styled(Select)`
    margin: 1em;
`

class SeasonEditor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            open: false,
            seasonEdit: false,
            start: new Date(),
            uid: '',
            newGame: true,
            id: id(),
            fetched: false,
            event: false,
        }
    }

    componentDidMount = () => {
        const { dispatch } = this.props
        dispatch(getEventTypes())
        this.checkData()
    }

    componentDidUpdate = () => {
        this.checkData()
    }

    checkData = () => {
        const { selected, dispatch, match } = this.props
        const { fetched } = this.state

        if (!selected && !fetched) {
            dispatch(getSeason(match.params.uid))
            this.setState({
                fetched: true,
            })
        }
    }

    open = (uid = false) => {
        if (!uid || typeof uid !== 'string') {
            this.setState({ open: true, newGame: true, id: id(), loading: false })
        } else {
            this.setState({
                open: true,
                newGame: false,
                uid,
                loading: false,
            })
        }
    }

    openSeasonEdit = () => {
        const { selected } = this.props
        this.setState({
            seasonEdit: true,
            start: dayjs(selected.start).toDate(),
        })
    }

    addEvent = state => {
        const { dispatch, match, types } = this.props

        this.setState({ loading: true })

        let vote

        if (state.voteValue.value !== 'NULL') {
            vote = {
                duration: parseInt(state.vote_duration, 10),
                options: {
                    number_to_vote_from: parseInt(state.number_to_vote_from, 10),
                    number_to_eliminate: parseInt(state.number_to_eliminate, 10),
                    number_of_tribes: parseInt(state.number_of_tribes),
                    type: state.voteValue.value,
                    vote_text: state.vote_text,
                },
            }
        }

        const newState = {}
        const typeModel = types.find(t => t.type === state.type)
        for (const item in state) {
            if (state.hasOwnProperty(item) && typeModel.hasOwnProperty(item)) {
                const field = typeModel.fields.find(f => f.value === item)
                if (field && field.type === 'NUMBER_INPUT') {
                    newState[item] = parseInt(state[item], 10)
                } else if (field && field.type === 'FLOAT_INPUT') {
                    newState[item] = parseFloat(state[item])
                } else if (field) {
                    newState[item] = state[item]
                }
            }
        }

        dispatch(
            addEvent(
                { ...newState, vote, start: state.start, fields: state.fields, type: state.type },
                match.params.uid
            )
        ).then(() => {
            this.setState({ open: false, loading: false })
            dispatch(getSeason(match.params.uid))
        })
    }

    editEvent = uid => state => {
        const { dispatch, match, types } = this.props

        this.setState({ loading: true })

        let vote
        console.log(state)
        if (state.voteValue.value !== 'NULL') {
            vote = {
                duration: parseInt(state.vote_duration, 10),
                options: {
                    number_to_vote_from: parseInt(state.number_to_vote_from, 10),
                    number_to_eliminate: parseInt(state.number_to_eliminate, 10),
                    type: state.voteValue.value,
                    vote_text: state.vote_text,
                    number_of_tribes: parseInt(state.number_of_tribes),
                },
            }
        }

        const newState = {}
        const typeModel = types.find(t => t.type === state.type)
        for (const item in state) {
            if (state.hasOwnProperty(item) && typeModel.hasOwnProperty(item)) {
                const field = typeModel.fields.find(f => f.value === item)
                if (field && field.type === 'NUMBER_INPUT') {
                    newState[item] = parseInt(state[item], 10)
                } else if (field && field.type === 'FLOAT_INPUT') {
                    newState[item] = parseFloat(state[item])
                } else if (field) {
                    newState[item] = state[item]
                }
            }
        }

        dispatch(
            editEvent(
                { uid, ...newState, vote, start: state.start, fields: state.fields },
                match.params.uid
            )
        ).then(() => {
            this.setState({ open: false, loading: false })
            dispatch(getSeason(match.params.uid))
        })
    }

    editSeason = e => {
        e.preventDefault()
        const { dispatch, match } = this.props
        const { start } = this.state

        dispatch(scheduleStart(match.params.uid, start.valueOf())).then(() => {
            this.setState({
                seasonEdit: false,
            })
            dispatch(getSeason(match.params.uid))
        })
    }

    removeEvent = uid => {
        const { dispatch, match } = this.props
        dispatch(removeEvent(uid, match.params.uid)).then(() => {
            this.setState({ open: false })
            dispatch(getSeason(match.params.uid))
        })
    }

    renderAddGameDialog = () => {
        const { open, newGame, event, uid, id: itemId, loading } = this.state

        const { types, selected } = this.props
        let eventToBeEdited = selected.events.find(item => item.uid === uid)
        const arrayOptions = {}
        if (eventToBeEdited && eventToBeEdited.type === 'GAME_ANAGRAM') {
            try {
                arrayOptions.category_options = types.find(
                    t => t.type === 'GAME_ANAGRAM'
                ).category_options
            } catch (e) {
                console.error('Cant find game anagram')
            }
        }
        if (eventToBeEdited) {
            eventToBeEdited = {
                uid,
                ...eventToBeEdited,
                options: {
                    ...eventToBeEdited.options,
                    ...arrayOptions,
                },
                vote: eventToBeEdited.vote
                    ? {
                          ...eventToBeEdited.vote,
                          ...eventToBeEdited.vote.options,
                          voteValue:
                              voteOptions.find(
                                  item =>
                                      eventToBeEdited.vote.options &&
                                      eventToBeEdited.vote.options.type &&
                                      eventToBeEdited.vote.options.type === item.value
                              ) || voteOptions[0],
                      }
                    : {},
            }
        }

        return (
            <Dialog
                open={open}
                handleClose={() => this.setState({ open: false })}
                label={newGame ? 'Add Game' : 'Edit Game'}
            >
                {newGame && (
                    <StyledSelect
                        styles={{ menu: provided => ({ ...provided, zIndex: 999 }) }}
                        value={event}
                        placeholder="Choose event"
                        isSearchable
                        options={types}
                        onChange={value => this.setState({ event: value, id: id() })}
                    />
                )}
                {newGame && event && (
                    <EventEditor
                        event={{ ...event, options: { ...event }, id: itemId }}
                        loading={loading}
                        handleSubmit={this.addEvent}
                    />
                )}
                {!newGame && eventToBeEdited && (
                    <EventEditor
                        event={eventToBeEdited}
                        loading={loading}
                        handleSubmit={this.editEvent(uid)}
                    />
                )}
                <Button
                    style={{ margin: '1em' }}
                    onClick={
                        newGame ? () => this.setState({ open: false }) : () => this.removeEvent(uid)
                    }
                >
                    {newGame ? 'Cancel' : 'Delete'}
                </Button>
            </Dialog>
        )
    }

    renderEditSeasonDialog = () => {
        const { start, seasonEdit } = this.state

        return (
            <Dialog
                open={seasonEdit}
                handleClose={() => this.setState({ seasonEdit: false })}
                label="Edit season"
            >
                <ContainerAsForm onSubmit={this.editSeason} style={{ marginTop: '1em' }}>
                    <Text>Start:</Text>
                    <StyledDateTimePicker
                        value={start}
                        onChange={date => this.setState({ start: date })}
                    />
                    <Button style={{ margin: '1em' }}>Save</Button>
                </ContainerAsForm>
            </Dialog>
        )
    }

    renderVoteString = vote => {
        if (vote && vote.options && vote.options.type && vote.options.type !== 'NULL') {
            return `${vote.options.type === 'INVERSED' ? 'Inversed' : 'Normal'} vote to eliminate ${
                vote.options.number_to_eliminate
            } people`
        }

        return 'No voting on this event'
    }

    render = () => {
        // const { open } = this.state
        const { selected, dispatch } = this.props
        const { events = [] } = selected
        let voteIndex = 0
        const splittedEvents = []
        events.forEach((item, index) => {
            if (item.type === 'VOTE') {
                splittedEvents.push({
                    ...item,
                    voteIndex,
                    time: item.start,
                    start: true,
                })
                splittedEvents.push({
                    ...item,
                    voteIndex,
                    time: item.end,
                    start: false,
                })
                voteIndex += 1
            } else {
                splittedEvents.push({
                    ...item,
                    index,
                    time: item.start,
                    start: true,
                })
                splittedEvents.push({
                    ...item,
                    index,
                    time: item.end,
                    start: false,
                })
            }
        })

        splittedEvents.sort((a, b) => a.time - b.time)

        if (selected) {
            const { name, status, uid, start = new Date().getTime() } = selected
            const startDate = dayjs(start)
            const today = dayjs().format('DD.MM.')
            let lastDate = today
            let i = 1

            return (
                <BoxContainer>
                    {this.renderAddGameDialog()}
                    {this.renderEditSeasonDialog()}
                    <Header>
                        <Text>Timeline</Text>
                    </Header>
                    <SecondaryHeader>
                        <Text bold size={1.1} style={{ marginRight: '1em' }}>
                            {name}
                        </Text>
                        <Text>
                            Scheduled: {startDate.format('DD.MM.')} -{' '}
                            {splittedEvents.length
                                ? dayjs(splittedEvents[splittedEvents.length - 1].time).format(
                                      'DD.MM.'
                                  )
                                : '>'}
                        </Text>
                    </SecondaryHeader>
                    <InnerContainer>
                        <Flex>
                            <Text bold size={1.1} style={{ marginRight: '.5em' }}>
                                Season Status:
                            </Text>
                            <Text size={1.1}>{STATUSES[status]}</Text>
                        </Flex>
                        {status === 'INITIALIZING' && (
                            <Container alignItems="flex-start" style={{ marginTop: '1.5em' }}>
                                <Text>STEPS TO START YOUR SEASON:</Text>
                                <Text>
                                    1. Create a new telegram group with the user More
                                    (@mobilereality_bot)
                                </Text>
                                <Text>2. Copy and send this message to the chat: /start {uid}</Text>
                                <Text>
                                    3. Congratz you are now ready for players! Click
                                    <Button onClick={() => dispatch(getSeason(uid))}> here </Button>
                                    to refresh!
                                </Text>
                            </Container>
                        )}
                        {status === 'ADDING_PLAYERS' && (
                            <Container alignItems="flex-start" style={{ marginTop: '1.5em' }}>
                                <Text>To add players, give them this link </Text>
                                <a href={`https://mobilereality.app/signup/${uid}`}>
                                    <Text>https://mobilereality.app/signup/{uid}</Text>
                                </a>
                            </Container>
                        )}
                        <Flex style={{ marginTop: '1.5em' }}>
                            <Container vertical={false} justifyContent="flex-start">
                                <HightlightButton
                                    color="white"
                                    onClick={this.openSeasonEdit}
                                    style={{ marginRight: '1.5em' }}
                                >
                                    Change Season
                                </HightlightButton>
                                <HightlightButton onClick={this.open}>+ Add Game</HightlightButton>
                            </Container>
                        </Flex>
                        <Flex>
                            <hr
                                style={{
                                    margin: '1.5em 0',
                                    borderColor: 'rgba(255, 255, 255, .2)',
                                }}
                            />
                        </Flex>

                        {/* ---------TIMELINE----------- */}

                        <Flex>
                            <Container vertical={false} justifyContent="flex-start">
                                <GreyText>Date</GreyText>
                                <GreyText style={{ marginRight: '3.7em' }}>Time</GreyText>
                                <GreyText>Action</GreyText>
                            </Container>
                        </Flex>
                        <Flex style={{ paddingLeft: MARGIN_LEFT }}>
                            <Dot />
                        </Flex>
                        <Flex>
                            <Container vertical={false} alignItems="stretch">
                                <ContentLeft>
                                    <Flex>
                                        <Container alignItems="flex-start">
                                            <ContentItem>
                                                <DateItem
                                                    today={startDate.format('DD.MM.') === today}
                                                >
                                                    <Text>{startDate.format('DD.MM.')}</Text>
                                                </DateItem>
                                            </ContentItem>
                                            {splittedEvents.map(item => {
                                                const itemTime = dayjs(item.time).format('DD.MM.')
                                                if (lastDate !== itemTime) {
                                                    const j = i
                                                    i = 1
                                                    lastDate = itemTime
                                                    return (
                                                        <ContentItem marginTop={j} key={item.time}>
                                                            <DateItem today={itemTime === today}>
                                                                <Text>{itemTime}</Text>
                                                            </DateItem>
                                                        </ContentItem>
                                                    )
                                                }
                                                i += 1.5

                                                return <React.Fragment key={item.time} />
                                            })}
                                        </Container>
                                    </Flex>
                                    <Flex>
                                        <Container alignItems="flex-start">
                                            <ContentItem>
                                                <Text>{startDate.format('HH:mm')}</Text>
                                            </ContentItem>
                                            {splittedEvents.map(item => (
                                                <ContentItem marginTop={1} key={item.time}>
                                                    <Text>{dayjs(item.time).format('HH:mm')}</Text>
                                                </ContentItem>
                                            ))}
                                        </Container>
                                    </Flex>
                                </ContentLeft>
                                <ContentRight>
                                    <ContentItem>
                                        <Cube />
                                        <Text>Season start</Text>
                                    </ContentItem>
                                    <ContentItem gray marginTop={0}>
                                        <Dash />
                                        <Text>Invitations will be sent for players</Text>
                                    </ContentItem>
                                    {splittedEvents.map(item =>
                                        item.type === 'VOTE' ? (
                                            <React.Fragment
                                                key={`vote_${item.voteIndex +
                                                    (item.start ? '_start' : '_end')}`}
                                            >
                                                <ContentItem marginTop={0.5}>
                                                    <Cube />
                                                    <Text>
                                                        Vote #{item.voteIndex + 1}{' '}
                                                        {item.start ? 'starts' : 'ends'}
                                                    </Text>
                                                </ContentItem>
                                                {!item.start ? (
                                                    <ContentItem gray marginTop={0}>
                                                        <Dash />
                                                        <Text
                                                            style={{
                                                                color: '#a63a3a',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            Results
                                                        </Text>
                                                    </ContentItem>
                                                ) : (
                                                    <ContentItem marginTop={0} />
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment
                                                key={item.index + (item.start ? '_start' : '_end')}
                                            >
                                                <ContentItem marginTop={0.5}>
                                                    <Cube />
                                                    <Text>
                                                        Game #{item.index + 1}{' '}
                                                        {item.title || item.name || ''}{' '}
                                                        {item.start ? 'starts' : 'ends'}
                                                    </Text>
                                                </ContentItem>
                                                {item.start ? (
                                                    <ContentItem gray marginTop={0}>
                                                        <Dash />
                                                        <Text
                                                            style={{
                                                                color:
                                                                    dayjs().valueOf() > item.time
                                                                        ? 'grey'
                                                                        : '#a63a3a',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => this.open(item.uid)}
                                                        >
                                                            Edit{' '}
                                                            {item.options
                                                                ? item.options.name ||
                                                                  item.options.title
                                                                : 'Undefined'}
                                                        </Text>
                                                    </ContentItem>
                                                ) : (
                                                    <ContentItem gray marginTop={0}>
                                                        <Dash />
                                                        <Text>
                                                            {this.renderVoteString(item.vote)}
                                                        </Text>
                                                    </ContentItem>
                                                )}
                                            </React.Fragment>
                                        )
                                    )}
                                </ContentRight>
                            </Container>
                        </Flex>
                        <Flex style={{ paddingLeft: MARGIN_LEFT }}>
                            <Dot />
                        </Flex>
                    </InnerContainer>
                </BoxContainer>
            )
        }

        return <Loading />
    }
}

SeasonEditor.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            uid: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    dispatch: PropTypes.func.isRequired,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    types: PropTypes.arrayOf(PropTypes.object).isRequired,
}

SeasonEditor.defaultProps = {
    selected: false,
}

const mapStateToProps = (state, { match }) => ({
    selected: state.seasons.seasons.find(season => season.uid === match.params.uid),
    types: state.events.types.map(item => ({ ...item, label: item.name, value: item.type })),
})

export default withRouter(connect(mapStateToProps)(SeasonEditor))
