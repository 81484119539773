import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Container from '../../Components/Container'
import Logo from '../../Components/Logo'
import Icon from '../../Components/Icon'
import Link from '../../Components/Link'
import Flex from '../../Components/Container/Flex'
import Text from '../../Components/Text'

const Header = ({ username }) => (
    <Container
        vertical={false}
        style={{ width: '100%', height: '5em', backgroundColor: 'rgba(255, 255, 255, 0.1)' }}
    >
        <Flex>
            <Container
                justifyContent="flex-start"
                vertical={false}
                style={{ paddingLeft: '2.65em' }}
            >
                <Link to="/">
                    <Logo height="1.75em" />
                </Link>
            </Container>
        </Flex>
        <Flex>
            <Container justifyContent="flex-end" vertical={false} style={{ paddingRight: '1em' }}>
                <Text>Hello, {username}</Text>
                <Link to="/profile">
                    <Icon
                        size="2.7em"
                        color="#B63939"
                        style={{
                            marginLeft: '0.3em',
                            borderRadius: '4em',
                        }}
                    >
                        account_circle
                    </Icon>
                </Link>
            </Container>
        </Flex>
    </Container>
)

Header.propTypes = {
    username: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    username: state.user.user.displayName,
})

export default connect(mapStateToProps)(Header)
