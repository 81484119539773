/**
 * Root reducer
 */

import { combineReducers } from 'redux'

// login reducer
import user from './user'
import utils from './utils'
import seasons from './seasons'
import events from './events'

// export root reducer
export default combineReducers({
    user,
    utils,
    seasons,
    events,
})
