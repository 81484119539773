import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Text from '../../Components/Text'

const Container = styled.div`
    flex: 1;
    max-width: 24em;

    margin: 0 1em;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
`

const InnerContainer = styled.div`
    max-height: 18em;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1em;
    flex: 1;
`

const Header = styled.div`
    font-size: 1.5em;
    font-weight: bold !important;
    background-color: ${({ color }) => color};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.7em;
    border-radius: 4px 4px 0 0;
`

const Box = ({ children, color, label }) => (
    <Container>
        <Header color={color}>
            <Text>{label}</Text>
        </Header>
        <InnerContainer>{children}</InnerContainer>
    </Container>
)

Box.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}

export default Box
