import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from '../Icon'
import Container from '../Container'
import Text from '../Text'

const CheckContainer = styled.div`
    width: 1em;
    height: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
    border-radius: 4px;
    margin: 1em 0.5em 1em 0;
`

const Checkbox = ({ checked, onChange, children }) => (
    <Container vertical={false} justifyContent="flex-start">
        <CheckContainer onClick={() => onChange(!checked)}>
            {checked && <Icon>check</Icon>}
        </CheckContainer>
        <Text>{children}</Text>
    </Container>
)

Checkbox.propTypes = {
    children: PropTypes.string,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
}

Checkbox.defaultProps = {
    children: '',
}

export default Checkbox
