import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'

import { auth } from '../../Config/auth'

import Container, { ContainerAsForm } from '../../Components/Container'
import Button from '../../Components/Button'
import Logo from '../../Components/Logo'
import { ErrorInput } from '../../Components/Input'
import { errorAction } from '../../Actions/utilsActions'
import { singin } from '../../Actions/authActions'
import Text from '../../Components/Text'

class Signin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            email: '',
            password: '',
            passwordConfirmation: '',
            firstName: '',
            lastName: '',
            telegramUsername: '',
            error: false,
        }
    }

    onChange = name => e => this.setState({ [name]: e.target.value })

    signin = e => {
        if (e) e.preventDefault()
        const {
            email,
            password,
            passwordConfirmation,
            firstName,
            lastName,
            telegramUsername,
        } = this.state
        const { dispatch } = this.props

        if (password !== passwordConfirmation) {
            return
        }

        dispatch(singin(email, password, firstName, lastName, telegramUsername)).then(() => {
            auth.signInWithEmailAndPassword(email, password).catch(error => {
                this.setState({ error: true })
                dispatch(errorAction(error.message))
            })
        })
    }

    render = () => {
        const {
            token,
            location: { state },
        } = this.props

        const {
            email,
            password,
            passwordConfirmation,
            firstName,
            lastName,
            telegramUsername,
            error,
        } = this.state

        if (token) {
            return <Redirect to={(state && state.from) || '/'} />
        }

        return (
            <Container style={{ flex: 1 }}>
                <Logo />
                <Text
                    bold
                    size={1.1}
                    style={{ color: 'white', width: '13.7em', marginTop: '2em', textAlign: 'left' }}
                >
                    CREATE ACCOUNT
                </Text>
                <ContainerAsForm
                    onSubmit={this.signin}
                    vertical
                    alignItems="stretch"
                    style={{ width: '15em' }}
                >
                    <ErrorInput
                        value={email}
                        onChange={this.onChange('email')}
                        error={error}
                        placeholder="email"
                    />
                    <ErrorInput
                        value={password}
                        onChange={this.onChange('password')}
                        error={error}
                        placeholder="password"
                        type="password"
                    />
                    <ErrorInput
                        value={passwordConfirmation}
                        onChange={this.onChange('passwordConfirmation')}
                        error={passwordConfirmation !== '' && passwordConfirmation !== password}
                        placeholder="repeat password"
                        type="password"
                    />
                    <ErrorInput
                        value={firstName}
                        onChange={this.onChange('firstName')}
                        error={error}
                        placeholder="first name"
                        type="firstName"
                    />
                    <ErrorInput
                        value={lastName}
                        onChange={this.onChange('lastName')}
                        error={error}
                        placeholder="last name"
                        type="lastName"
                    />
                    <ErrorInput
                        value={telegramUsername}
                        onChange={this.onChange('telegramUsername')}
                        error={error}
                        placeholder="telegram username"
                        type="telegramUsername"
                    />
                </ContainerAsForm>
                <Container
                    vertical={false}
                    justifyContent="space-between"
                    style={{ fontSize: '.7em', width: '21.4285714em', marginTop: '4em' }}
                >
                    <Link to="/login">
                        <Button style={{ color: 'rgba(147, 56, 59, 0.9' }}>back</Button>
                    </Link>
                    <Button onClick={this.signin}>create</Button>
                </Container>
            </Container>
        )
    }
}

Signin.propTypes = {
    token: PropTypes.string.isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            from: PropTypes.string,
        }),
    }),
    dispatch: PropTypes.func.isRequired,
}

Signin.defaultProps = {
    location: {
        state: {
            from: '/',
        },
    },
}

const mapStateToProps = state => ({
    token: state.user.token,
})

export default connect(mapStateToProps)(Signin)
