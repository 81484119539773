/* eslint-disable indent */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { withTheme } from 'styled-components'

import Dashboard from './Dashboard'
import Profile from './Profile'
import Create from './Create'
import Login from './Login'
import Signin from './Signin'
import Forgot from './Forgot'
import Header from './Header'
import Sidebar from './Sidebar'
// import Form from './Form'

import ProtectedRoute from '../Components/Auth'
import Text from '../Components/Text'
import Utils from '../Components/Utils'
import Container from '../Components/Container'
import Flex from '../Components/Container/Flex'

const Main = ({ loggedIn }) => {
    return (
        <Router>
            <Utils />
            <Switch>
                {/* <Route path="/:uid/form" exact component={Form} /> */}
                <Route>
                    <ProtectedRoute redirect={false} component={Header} />
                    <Container
                        vertical={false}
                        alignItems="stretch"
                        style={
                            loggedIn
                                ? { height: 'calc(100% - 5em)' }
                                : { justifyContent: 'center', height: '100%' }
                        }
                    >
                        <ProtectedRoute redirect={false} component={Sidebar} />
                        <Flex
                            style={
                                !loggedIn
                                    ? {
                                          display: 'flex',
                                          justifyContent: 'center',
                                      }
                                    : {
                                          height: '100%',
                                          overflowY: 'auto',
                                      }
                            }
                        >
                            <Switch>
                                <Route path="/login" exact component={Login} />
                                <Route path="/signin" exact component={Signin} />
                                <Route path="/forgot" exact component={Forgot} />
                                <ProtectedRoute path="/profile" exact component={Profile} />
                                <ProtectedRoute path="/create" exact component={Create} />
                                <ProtectedRoute path="/" component={Dashboard} />

                                <Route>
                                    <Text style={{ marginTop: '1em' }}>404 - no such an url</Text>
                                </Route>
                            </Switch>
                        </Flex>
                    </Container>
                </Route>
            </Switch>
        </Router>
    )
}

Main.propTypes = {
    theme: PropTypes.shape({
        colors: PropTypes.object.isRequired,
        border: PropTypes.shape({
            default: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    loggedIn: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    loggedIn: state.user.token !== '',
})

export default withTheme(connect(mapStateToProps)(Main))
