export const GET_SEASONS = 'GET_SEASONS'
export const GET_SEASONS_SUCCESS = 'GET_SEASONS_SUCCESS'
export const GET_SEASONS_FAILURE = 'GET_SEASONS_FAILURE'

export const GET_SEASON = 'GET_SEASON'
export const GET_SEASON_SUCCESS = 'GET_SEASON_SUCCESS'
export const GET_SEASON_FAILURE = 'GET_SEASON_FAILURE'

export const ADD_SEASON = 'ADD_SEASON'
export const ADD_SEASON_SUCCESS = 'ADD_SEASON_SUCCESS'
export const ADD_SEASON_FAILURE = 'ADD_SEASON_FAILURE'

export const SCHEDULE_START = 'SCHEDULE_START'
export const SCHEDULE_START_SUCCESS = 'SCHEDULE_START_SUCCESS'
export const SCHEDULE_START_FAILURE = 'SCHEDULE_START_FAILURE'

export const JOIN_SEASON = 'JOIN_SEASON'
export const JOIN_SEASON_SUCCESS = 'JOIN_SEASON_SUCCESS'
export const JOIN_SEASON_FAILURE = 'JOIN_SEASON_FAILURE'
